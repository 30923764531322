<template>
  <v-img
    :src="src"
    gradient="to top, rgba(0,0,0,.5), rgba(0,0,0,.2)"
    height="200"
  >
    <div class="login-header">
      <v-row>
        <v-col
          md="3"
          class="pl-12 pt-6 ml-12 white--text display-2 font-weight-medium"
        >
          LOGIN PAGE
        </v-col>
        <v-spacer />
        <v-col md="4">
          <v-btn class="white--text float-right" color="#99999966">
            <v-icon class="mr-1">mdi-fingerprint</v-icon>登录
          </v-btn>
          <v-btn text class="white--text float-right" @click="clickRegister">
            <v-icon class="mr-1">mdi-account-multiple-plus</v-icon>注册
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="login-vue">
      <base-material-card
        icon="mdi-twitter"
        color="primary"
        cardColor="#333333cc"
      >
        <div class="px-8 py-4">
          <v-text-field label="用户名" v-model="account" dark> </v-text-field>
          <v-text-field label="密码" type="password" v-model="pwd" dark>
          </v-text-field>
          <v-btn
            color="primary"
            class="my-4"
            style="width: 250px;"
            @click="submit"
          >
            登录
          </v-btn>
        </div>
      </base-material-card>
    </div>
  </v-img>
</template>

<script>
import BaseMaterialCard from "./MaterialCard";
export default {
  name: "Login",
  data() {
    return {
      // account: "admin",
      // pwd: "admin",
      src: `${process.env.BASE_URL}image/login.jpg`,
      account: "",
      pwd: "",
      isShowLoading: false,
    };
  },
  components: { BaseMaterialCard },
  created() {},
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    clickRegister() {
      this.$notify.warning({
        message: "未开放注册！",
      });
    },
    submit() {
      if (this.account === "admin" && this.pwd === "admin") {
        this.isShowLoading = true;
        // 登陆成功 设置用户信息
        localStorage.setItem("account", "admin");
        localStorage.setItem("userName", "管理员");
        // 登陆成功 假设这里是后台返回的 token
        //localStorage.setItem("token", "i_am_token");
        this.$router.push({ path: this.redirect || "/" });
      } else {
        this.$notify.error({
          message: "账号密码错误！",
        });
      }
    },
  },
};
</script>

<style>
.login-header {
  height: 10%;
  min-height: 30px;
  width: 100%;
  position: fixed;
}
.login-vue {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.v-footer {
  border-top: 0px !important ;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 111111s;
  -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
  transition-delay: 111111s;
  transition: color 11111s ease-out, background-color 111111s ease-out;
}
</style>
